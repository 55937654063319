import React, { useEffect, useMemo, useState } from "react"
import { FormInputAntd, FormSelectAntd, Map, EditableTable, NearbyEmployeesModal, AntdButton } from "components"
import { useForm, useFieldArray, SubmitHandler, FormProvider } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import {
	locationContactDetailsInitialValues,
	locationDetailsInitialValues,
	locationDetailsValidationSchema,
} from "../../../Schema"
import { selectMediaURL, selectLoadingMedia, clearMedia } from "store/Media/media.slice"
import { useAppDispatch, useAppSelector } from "hooks"
import { isSavingLocation, saveLocation, selectLocationById } from "../../../store/Location/locationDetails.slice"
import { GetLocationDetailsFormInterface, GetLocationDetailsInterface } from "@type/locations.types"
import { selectBranchList } from "store/Branches/branch.slice"
import { selectCustomerOptions } from "store/Customers/customers.slice"
import { selectSDCountiesDropdownList } from "store/StaticData/SDCounties.slice"
import {
	selectPostalCodeData,
	isLoadingPostalCodeCoordinates,
	selectPostalCodeErrors,
	getEmployeesNearbyLocation,
} from "store/Geolocation/geolocation.slice"
import { AllowedTo } from "react-abac"
import { Permissions, VALIDATION_REGEX } from "config/constants"
import { Entities } from "types/auditTrail.types"
import AuditTrail from "components/AuditTrail"
import { selectSelectedLocation } from "store/globalState.slice"
import { removeWhiteSpaces } from "config/utils"
import { Card } from "antd-v5"

interface DetailFormHandler extends GetLocationDetailsFormInterface {}

const LocationDetail: React.FC = () => {
	const dispatch = useAppDispatch()

	const [isModalOpen, setIsModalOpen] = useState(false)

	const selected = useAppSelector(selectSelectedLocation)

	const [imageURL] = useAppSelector(selectMediaURL())
	const imageURLLoading = useAppSelector(selectLoadingMedia())
	const saving = useAppSelector(isSavingLocation())
	const location = useAppSelector(selectLocationById(selected))
	const branches = useAppSelector(selectBranchList())
	const customers = useAppSelector(selectCustomerOptions)
	const allCounties = useAppSelector(selectSDCountiesDropdownList) || []
	const methods = useForm<DetailFormHandler>({
		resolver: yupResolver(locationDetailsValidationSchema),
	})
	const {
		handleSubmit,
		register,
		reset,
		getValues,
		setError,
		clearErrors,
		setValue,
		control,
		watch,
		formState: { errors, isDirty, dirtyFields, isSubmitting },
	} = methods
	const position = useAppSelector(selectPostalCodeData(watch("postalCode")))
	const isLoadingCoordinates = useAppSelector(isLoadingPostalCodeCoordinates(watch("postalCode")))
	const isGoogleMapFound = useAppSelector(selectPostalCodeErrors(watch("postalCode")))
	const { append, update, remove } = useFieldArray({
		control,
		name: "contactDetails" as never,
	})

	useEffect(() => {
		if (location) {
			dispatch(clearMedia())
			reset({
				...locationDetailsInitialValues,
				...location,
				county: location.county?._id || "",
				branch: location.branch?._id || "",
				customer: location.customer?._id || "",
			})
		} else {
			dispatch(clearMedia())
			reset({ ...locationDetailsInitialValues })
		}
	}, [location, selected])

	useEffect(() => {
		if (imageURL) {
			setValue("thumbnail", imageURL, { shouldDirty: true })
			dispatch(clearMedia())
		}
	}, [imageURL])

	useEffect(() => {
		if (!isGoogleMapFound) {
			clearErrors("postalCode")
		} else {
			setError("postalCode", {
				type: "custom",
				message: `Postal Code Not Found`,
			})
		}
	}, [isGoogleMapFound])

	const onSubmit: SubmitHandler<DetailFormHandler> = data => {
		if (!errors.postalCode) dispatch(saveLocation({ ...(data as GetLocationDetailsInterface), position }))
	}

	const postalCode = useMemo(() => {
		if (
			!!watch("postalCode") &&
			VALIDATION_REGEX.postalCode.test(removeWhiteSpaces(watch("postalCode"))) &&
			errors.postalCode
		)
			return watch("postalCode")
		else return ""
	}, [watch("postalCode")])

	return (
		<>
			<div className="p-2">
				<AuditTrail id={selected} name={Entities.LOCATIONS} />
			</div>
			<FormProvider {...methods}>
				<form onSubmit={handleSubmit(onSubmit)}>
					<div className="grid grid-cols-2 gap-x-6">
						<div className="grid grid-cols-2 gap-y-4 gap-x-5">
							<FormSelectAntd
								disabled={!branches.length}
								label="Branch"
								name="branch"
								options={[
									{ label: "Select Branch", value: "" },
									...branches.map(({ _id, name }) => ({ label: name, value: _id })),
								]}
							/>
							<FormSelectAntd
								disabled={!customers.length}
								label="Customer"
								name="customer"
								options={[{ label: "Select Customer", value: "" }, ...customers]}
							/>

							<FormInputAntd type="text" label="Full Name" name="name" />
							<FormInputAntd type="text" label="Short Name" name="shortName" />

							<FormInputAntd disabled={true} type="text" label="SIN" name="sin" />
							<FormInputAntd type="text" label="Client Sin" name="clientSin" />

							<FormInputAntd type="text" label="Address Line 1" name="addressLine1" />
							<FormInputAntd type="text" label="Address Line 2" name="addressLine2" />

							<FormInputAntd type="text" label="City" name="city" />
							<FormSelectAntd
								disabled={!allCounties?.length}
								label="County"
								name="county"
								options={allCounties}
							/>

							<FormInputAntd
								type="text"
								label="PostalCode"
								name="postalCode"
								loading={isLoadingCoordinates}
							/>

							<FormInputAntd type="text" label="Telephone" name="telephone" />

							<AllowedTo perform={Permissions.UPDATE_LOCATION_DETAILS}>
								<div className="flex gap-3 py-2">
									<AntdButton
										disabled={
											!isDirty || isSubmitting || imageURLLoading || !!Object.keys(errors).length
										}
										loading={saving}
										htmlType="submit"
									>
										{selected ? "Update" : "Save"}
									</AntdButton>
									<AntdButton
										onClick={e => {
											e.preventDefault()
											reset({ ...locationDetailsInitialValues, _id: getValues("_id") })
											dispatch(clearMedia())
										}}
									>
										Clear
									</AntdButton>
								</div>
							</AllowedTo>
						</div>
						<div className="space-y-5">
							<FormInputAntd type="textarea" label="Notes" name="notes" />
							<Card
								size="small"
								title="Postal Code Location"
								className="h-full w-full"
								bodyStyle={{ height: "92%", padding: "5px" }}
							>
								<Map
									isDirty={dirtyFields?.postalCode}
									postalCode={postalCode}
									errors={errors}
									coordinates={location?.position}
								/>
								<div className="relative">
									{location && (
										<div className="absolute bottom-2 left-2">
											<AntdButton
												type="default"
												className="border border-primary1 bg-white"
												onClick={e => {
													e.preventDefault()
													setIsModalOpen(true)
													dispatch(getEmployeesNearbyLocation(location?._id, "ALL"))
												}}
												htmlType="button"
											>
												Find Nearby Employees
											</AntdButton>
										</div>
									)}
								</div>
							</Card>

							<EditableTable
								append={append}
								update={update}
								errors={errors}
								remove={remove}
								register={register}
								fieldArrayName={"contactDetails"}
								initialValues={locationContactDetailsInitialValues}
								rows={watch("contactDetails") ? watch("contactDetails") : []}
								columns={[
									{
										label: "Sr. No",
										key: "serialNumber",
										render: (data: any) => {
											return <div>{watch("contactDetails").indexOf(data.row) + 1}</div>
										},
									},
									{
										label: "Name",
										key: "name",
										render: ({ row, key }: any) => {
											return <div>{row[key]}</div>
										},
									},
									{
										label: "Designation",
										key: "designation",
										render: ({ row, key }: any) => {
											return <div>{row[key]}</div>
										},
									},
									{
										label: "Phone",
										key: "phone",
										render: ({ row, key }: any) => {
											return <div>{row[key]}</div>
										},
									},
								]}
							/>
						</div>
					</div>
				</form>
			</FormProvider>
			<NearbyEmployeesModal
				modalHandler={(close: boolean) => setIsModalOpen(close)}
				open={isModalOpen}
				location={location}
			/>
		</>
	)
}

export default LocationDetail
