import moment from "moment"
import { theme } from "config"
import ReactApexChart from "react-apexcharts"
import useAppSelector from "hooks/useAppSelector"
import { ApexOptions } from "apexcharts"
import React, { useMemo } from "react"
import { selectSelectedLocationTrends } from "../../store/Location/locationDetails.slice"
import { DashboardDateFilter } from "../../config/constants"

interface ApexChartProps {
	selectedPicker: DashboardDateFilter
}
type keyTypes = "selfCoveredHours" | "otherCoveredHours"

const CHART_TITLE = {
	[DashboardDateFilter.DAILY]: "past 30 days",
	[DashboardDateFilter.WEEKLY]: "past 12 weeks",
	[DashboardDateFilter.MONTHLY]: "past 12 months",
}

const keysToPlot: { label: string; key: keyTypes }[] = [
	{ label: "Supplier Covered Hours", key: "otherCoveredHours" },
	{ label: "Self Covered Hours", key: "selfCoveredHours" },
]
const ApexChart: React.FC<ApexChartProps> = ({ selectedPicker }) => {
	const trendsData = useAppSelector(selectSelectedLocationTrends())

	const sortedTrendsData = useMemo(() => {
		const data =
			trendsData
				?.slice()
				.sort((a, b) => moment(a._id).startOf("d").valueOf() - moment(b._id).startOf("d").valueOf()) || []

		return data
	}, [trendsData])

	const dateFilter = useMemo(() => {
		return selectedPicker
	}, [sortedTrendsData])

	const xCategories = useMemo(() => {
		return sortedTrendsData?.map(trend => trend._id)
	}, [sortedTrendsData])
	const stepSize = useMemo(() => {
		return 12
	}, [xCategories])

	const options: ApexOptions = {
		chart: {
			height: 350,
			type: "bar",
			stacked: true,
			toolbar: {
				show: false,
			},
		},
		stroke: {
			curve: "smooth",
			colors: ["transparent"],
			width: 2,
		},
		grid: {
			show: false,
		},
		dataLabels: {
			enabled: false,
		},
		plotOptions: {
			bar: {
				dataLabels: {
					total: {
						enabled: true,
						formatter: val => {
							return Math.round(+(val ?? 0)).toString()
						},
						style: {
							fontSize: "8px",
							fontWeight: 900,
						},
					},
				},
			},
		},
		title: {
			text: `Shift Hours History (${CHART_TITLE[dateFilter]})`,
			align: "center",
			style: {
				color: "black",
				fontFamily: "Poppins",
				fontSize: "16px",
				fontWeight: 600,
			},
		},
		xaxis: {
			categories: xCategories,
			labels: {
				show: true,
				rotate: -45,
				rotateAlways: true,
				minHeight: 100,
				maxHeight: 180,
				formatter: function (value) {
					const parsedDate = moment(value, "YYYY-MM-DD")
					switch (dateFilter) {
						case DashboardDateFilter.MONTHLY:
							return parsedDate.format("MMM-YY")
						default:
							return parsedDate.format("DD-MMM")
					}
				},
			},
			tickAmount: stepSize,
			title: {
				text: "Timeline",
				offsetX: -20,
				style: {
					color: "black",
					fontFamily: "Poppins",
					fontSize: "16px",
					fontWeight: 600,
				},
			},
		},
		colors: [
			theme.colors.danger,
			theme.colors.success,
			theme.colors.warning,
			theme.colors["danger-light"],
			theme.colors.tangerine,
		],
		yaxis: {
			tickAmount: 4,
			title: {
				text: "Hours worked",
				style: {
					color: "black",
					fontFamily: "Poppins",
					fontSize: "16px",
					fontWeight: 600,
				},
			},
			labels: {
				formatter: function (value) {
					return value.toLocaleString("en-US")
				},
			},
		},
		legend: {
			position: "bottom",
			markers: {
				fillColors: [
					theme.colors.danger,
					theme.colors.success,
					theme.colors.warning,
					theme.colors["danger-light"],
					theme.colors.tangerine,
				],
			},
			// offsetY: -30,
			// offsetX: -20,
		},
		tooltip: {
			y: {
				formatter: function (value, options) {
					let percentage = 0
					if (sortedTrendsData && xCategories && xCategories.length && sortedTrendsData.length) {
						const _id = xCategories[options.dataPointIndex]
						const trend = sortedTrendsData.find(data => data._id === _id)
						if (options.seriesIndex === 0 && trend) {
							percentage = (value * 100) / (trend.selfCoveredHours + trend.otherCoveredHours)
						} else if (options.seriesIndex === 1 && trend) {
							percentage = (value * 100) / (trend.selfCoveredHours + trend.otherCoveredHours)
						}
					}
					return `${value} (${Number(percentage).toFixed(2)}%)`
				},
			},
		},
	}
	const series = useMemo(() => {
		return sortedTrendsData
			? keysToPlot.map(({ key, label }) => ({
					name: label,
					data: sortedTrendsData.map(trend => {
						if (key === "selfCoveredHours" || key === "otherCoveredHours") {
							return trend[key]
						} else {
							return 0
						}
					}),
			  }))
			: []
	}, [sortedTrendsData])

	return (
		<div className="h-full">
			{sortedTrendsData?.length ? (
				<div id="charts" className="min-w-[580px] overflow-auto">
					<ReactApexChart options={options} series={series} type="bar" height={300} width={580} />
				</div>
			) : (
				<div className="flex h-full items-center justify-center py-5">
					<span className="text-xl font-semibold">No Data</span>
				</div>
			)}
		</div>
	)
}

export default ApexChart
