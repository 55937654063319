import React, { useEffect, useMemo, useRef, useState } from "react"
import { Map, FormInputAntd, FormSelectAntd, AntdButton, WhatsAppButton } from "components"
import { useForm, SubmitHandler, FormProvider } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { detailsValidationSchema, detailsInitialValues } from "../../Schema"
import { selectLoadingMedia } from "store/Media/media.slice"
import { useAppDispatch, useAppSelector } from "hooks"
import { getEmployeesByBranchId, isEmployeeSaving, saveEmployee, selectEmployeeById } from "store/Employee/detail.slice"
import { GetEmployeeInterface, GetEmployeeFormInterface } from "@type/employees.types"
import { selectBranchesMappedAsOptions } from "store/Branches/branch.slice"
import { selectSDCountiesDropdownList } from "store/StaticData/SDCounties.slice"
import { selectSDSuppliersDropdownList } from "store/StaticData/SDSuppliers.slice"
// import QualificationTable from "./HR/QualificationTable"
import {
	getEmployeesQualificationByEmployeeId,
	selectEmployeeQualificationData,
} from "store/Employee/Qualification/qualification.slice"
import {
	selectPostalCodeData,
	selectPostalCodeErrors,
	isLoadingPostalCodeCoordinates,
} from "store/Geolocation/geolocation.slice"

import SIAForm from "./SIAForm"
import { AllowedTo } from "react-abac"
import { DATE_FORMATS, Permissions, VALIDATION_REGEX, employeeTypeOptions } from "config/constants"
import AuditTrail from "components/AuditTrail"
import { Entities } from "types/auditTrail.types"
import {
	clearSelectedBranch,
	clearSelectedSupplier,
	selectSelectedEmployee,
	setSelectedEmployeeBranch,
	setSelectedSupplier,
} from "store/globalState.slice"
import { removeWhiteSpaces } from "config/utils"
import { Card } from "antd-v5"
import UploadImageThumbnail from "./UploadImageThumbnail"
import QualificationTable from "./HR/QualificationTable"
import moment from "moment"

interface DetailFormHandler extends GetEmployeeFormInterface {}

const EmployeeDetail: React.FC = () => {
	const dispatch = useAppDispatch()
	const selected = useAppSelector(selectSelectedEmployee)
	const qualifications = useAppSelector(selectEmployeeQualificationData())
	const saving = useAppSelector(isEmployeeSaving())
	const [selectedQualification, setSelectedQualification] = useState<string>("")
	const allCounties = useAppSelector(selectSDCountiesDropdownList) || []
	const suppliers = useAppSelector(selectSDSuppliersDropdownList)
	const imageURLLoading = useAppSelector(selectLoadingMedia())
	const user = useAppSelector(selectEmployeeById(selected))
	const branches = useAppSelector(selectBranchesMappedAsOptions())
	const methods = useForm<DetailFormHandler>({
		resolver: yupResolver(detailsValidationSchema),
		mode: "onChange",
	})
	const {
		handleSubmit,
		reset,
		getValues,
		setError,
		clearErrors,
		setValue,
		watch,
		formState: { errors, isDirty, isSubmitting, dirtyFields },
	} = methods

	const position = useAppSelector(selectPostalCodeData(watch("postalCode")))
	const isLoadingGoogleMap = useAppSelector(isLoadingPostalCodeCoordinates(watch("postalCode")))
	const isGoogleMapFound = useAppSelector(selectPostalCodeErrors(watch("postalCode")))
	const isDefaultQualificationSelected = useRef(false)
	useEffect(() => {
		if (user !== (null || undefined)) {
			reset({
				...detailsInitialValues,
				...user,
				password: "#",
				confirmPassword: "#",
				nin: "#",
				county: user?.county?._id ? user?.county?._id : detailsInitialValues.county,
			})
		}
	}, [user])

	useEffect(() => {
		if (!selected) {
			reset(detailsInitialValues)
		}
	}, [selected])

	useEffect(() => {
		if (selected) dispatch(getEmployeesQualificationByEmployeeId(selected))
		setSelectedQualification("")
		isDefaultQualificationSelected.current = false
	}, [selected])

	useEffect(() => {
		if (watch("branch") && watch("supplier")) setValue("supplier", "", { shouldDirty: true })
	}, [watch("branch")])

	useEffect(() => {
		if (watch("branch") && watch("supplier")) setValue("branch", "", { shouldDirty: true })
	}, [watch("supplier")])

	useEffect(() => {
		if (qualifications?.length && !selectedQualification && !isDefaultQualificationSelected.current) {
			const currentDate = moment()
			//Finding the qualification with the nearest finishDate in the future
			const closestObject = qualifications.reduce((closest, current) => {
				const currentFinishDate = moment(current.finishDate, DATE_FORMATS.DD_MM_YYYY)
				const closestFinishDate = moment(closest.finishDate, DATE_FORMATS.DD_MM_YYYY)

				return currentFinishDate.isAfter(currentDate) && currentFinishDate.isBefore(closestFinishDate)
					? current
					: closest
			}, qualifications[0])
			isDefaultQualificationSelected.current = true
			setSelectedQualification(closestObject?._id || qualifications[0]._id)
		}
	}, [qualifications])

	useEffect(() => {
		if (!isGoogleMapFound) {
			clearErrors("postalCode")
		} else {
			setError("postalCode", {
				type: "custom",
				message: `Postal Code Not Found`,
			})
		}
	}, [isGoogleMapFound])

	const postalCode = useMemo(() => {
		if (
			!!watch("postalCode") &&
			VALIDATION_REGEX.postalCode.test(removeWhiteSpaces(watch("postalCode"))) &&
			errors.postalCode
		)
			return watch("postalCode")
		else return ""
	}, [watch("postalCode")])

	const onSubmit: SubmitHandler<DetailFormHandler> = data => {
		const propertiesToDelete = ["password", "confirmPassword", "userName"]
		if (propertiesToDelete.every((item: string) => data[item as keyof typeof data] === "#")) {
			propertiesToDelete.forEach(item => delete data[item as keyof typeof data])
		}

		dispatch(
			saveEmployee(
				{ ...(data as GetEmployeeInterface), position },
				(fields: { branch?: string; supplier?: string }) => {
					if (!data._id) {
						if (fields?.branch) {
							dispatch(setSelectedEmployeeBranch(fields.branch))
							dispatch(getEmployeesByBranchId({ branchId: fields.branch }))
							dispatch(clearSelectedSupplier())
						} else if (fields?.supplier) {
							dispatch(setSelectedSupplier(fields.supplier))
							dispatch(getEmployeesByBranchId({ supplierId: fields.supplier }))
							dispatch(clearSelectedBranch())
						}
					}
				},
			),
		)
	}

	return (
		<>
			<AuditTrail id={selected} name={Entities.EMPLOYEE_DETAILS} />
			{/* {selected ? (
					<div className="flex items-center gap-5">
						<span>
							<strong>Mobile App Username: </strong>
							{user?.userName || ""}
						</span>
						<span>
							<strong>PIN: </strong> {user?.pin || ""}
						</span>
					</div>
				) : undefined} */}
			<div className="grid grid-cols-12 space-x-4">
				<div className="col-span-6">
					<FormProvider {...methods}>
						<form className="pb-2.75" onSubmit={handleSubmit(onSubmit)}>
							{/* <div className="grid grid-cols-5 gap-x-2">
								<div className="col-span-2 flex flex-col space-y-2">
									<FormSelectAntd
										disabled={!suppliers.length}
										label="Supplier"
										name="supplier"
										options={suppliers}
									/>
									<FormInputAntd type="text" label="Last Name" name="lastName" />
								</div>
								<div className="col-span-2 flex flex-col space-y-2">
									<FormSelectAntd
										disabled={!branches.length}
										label="Branch"
										name="branch"
										options={[{ label: "Select Branch", value: "" }, ...branches]}
									/>
									<FormInputAntd type="text" label="First Name" name="firstName" />
								</div>
								<UploadImageThumbnail
									image={user?.thumbnail}
									name={user?.firstName as string}
									onImageChange={filePath => {
										dispatch(saveEmployee({ ...user, thumbnail: filePath }, () => undefined))
									}}
								/>
							</div> */}
							<div className="grid grid-cols-2 gap-x-5.5 gap-y-2 py-4">
								<div className="flex flex-col gap-y-2">
									<FormInputAntd type="text" label="NI Number" name="nin" hidden={!!selected} />
									<FormInputAntd type="text" label="First Name" name="firstName" />
									<FormInputAntd type="text" label="Last Name" name="lastName" />
									<FormInputAntd type="text" label="Short Name" name="shortName" />
								</div>
								<div className="flex w-full items-center justify-center">
									<div className="h-40 w-40">
										<UploadImageThumbnail
											image={user?.thumbnail}
											name={user?.firstName as string}
											onImageChange={filePath => {
												if (user?._id) {
													dispatch(
														saveEmployee(
															{
																...user,
																...(user.county._id ? { county: user.county._id } : {}),
																position,
																thumbnail: filePath,
															} as GetEmployeeInterface,
															() => undefined,
														),
													)
												} else {
													setValue("thumbnail", filePath)
												}
											}}
										/>
									</div>
								</div>
								<FormSelectAntd
									disabled={!branches.length}
									label="Branch"
									name="branch"
									options={[{ label: "Select Branch", value: "" }, ...branches]}
								/>
								<FormSelectAntd
									disabled={!suppliers.length}
									label="Supplier"
									name="supplier"
									options={suppliers}
								/>

								<FormInputAntd
									autoComplete="new-password"
									type="password"
									label="Password"
									name="password"
									hidden={!!selected}
								/>

								<FormInputAntd
									type="password"
									autoComplete="new-password"
									label="Confirm Password"
									name="confirmPassword"
									hidden={!!selected}
								/>
								<FormSelectAntd
									disabled={!employeeTypeOptions.length}
									label="Type"
									name="type"
									options={employeeTypeOptions}
								/>
								<FormInputAntd type="text" label="Address Line 1" name="addressLine1" />
								<FormInputAntd type="text" label="Address Line 2" name="addressLine2" />
								<FormInputAntd type="text" label="City" name="city" />
								<FormSelectAntd
									disabled={!allCounties?.length}
									label="County"
									name="county"
									options={allCounties}
								/>
								<FormInputAntd
									type="text"
									label="PostalCode"
									name="postalCode"
									loading={isLoadingGoogleMap}
								/>
								<FormInputAntd type="text" label="Mobile" name="mobile" />
								<FormInputAntd type="text" label="Telephone" name="telephone" />
								<FormInputAntd type="text" label="Email" name="email" />
								<FormInputAntd type="text" label="Client Pin" name="clientPin" />
							</div>
							<AllowedTo perform={Permissions.UPDATE_EMPLOYEE_DETAILS}>
								<div className="flex gap-x-2">
									<AntdButton
										onClick={e => {
											e.preventDefault()
											reset({ ...detailsInitialValues, _id: getValues("_id") })
										}}
									>
										Clear
									</AntdButton>
									<AntdButton
										loading={saving}
										disabled={
											!isDirty || isSubmitting || imageURLLoading || !!Object.keys(errors).length
										}
										htmlType="submit"
									>
										{selected ? "Update" : "Save"}
									</AntdButton>
								</div>
							</AllowedTo>
						</form>
					</FormProvider>
				</div>
				<div className="col-span-6 space-y-5">
					<div>
						<div className="grid grid-cols-2 gap-5">
							<div className="col-span-1">
								<Card size="small" title="SIA Details">
									<SIAForm
										setSelectedQualification={setSelectedQualification}
										selectedQualification={selectedQualification}
									/>
								</Card>
							</div>

							<div className="col-span-1">
								<Card
									size="small"
									title="Postal Code Location"
									className="h-full w-full"
									bodyStyle={{ height: "85%", padding: "0px" }}
								>
									<Map
										isDirty={dirtyFields?.postalCode}
										coordinates={user?.position}
										postalCode={postalCode}
										errors={errors}
										zoom={14}
										showDistance={false}
									/>
								</Card>
							</div>
						</div>
					</div>
					<div className="h-fit py-2">
						<QualificationTable
							showActions={false}
							selectedRow={selectedQualification}
							onRowClick={(id: string) => {
								if (selectedQualification !== id) setSelectedQualification(id)
							}}
						/>
					</div>
				</div>
			</div>

			<WhatsAppButton phone={parseInt(user?.mobile || "") || 0} />
		</>
	)
}
export default EmployeeDetail
