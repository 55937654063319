import Icon from "./Icon"
import Select from "./Select"

interface SelectOptions {
	label: string
	value: string
}

interface Props extends React.SelectHTMLAttributes<HTMLSelectElement> {
	options: SelectOptions[]
	label?: string | null
	containerClass?: string
	loading?: boolean
	name: string
	multiple?: boolean
}
const SelectWithoutRegister: React.FC<Props> = ({
	loading,
	label = "",
	name,
	options = [],
	containerClass = "",
	...rest
}) => (
	<div className={`flex flex-col ${containerClass}`}>
		{label && <label className="text-dark-alt">{label}</label>}

		{loading ? (
			<div
				className={`!bg-primary flex h-[51px] w-84 items-center justify-center rounded-md border border-secondary-dark px-3 py-3.5 text-md text-black outline-none transition ease-in-out placeholder:text-secondary-dark hover:border-black disabled:cursor-not-allowed disabled:bg-secondary-light disabled:hover:border-secondary-light`}
			>
				<Icon name="spinner" size={15} color="black" className="ml-auto animate-spin" />
			</div>
		) : (
			<Select className="text-sm" {...rest}>
				<option value={""}>{name}</option>
				{options.map(({ label, value }: SelectOptions) => (
					<option key={value} value={value}>
						{label}
					</option>
				))}
			</Select>
		)}
	</div>
)

// const SelectSkeleton = () => {
// 	return (
// 		<div>
// 			<div className="flex w-full animate-pulse flex-col gap-5 px-2.5 py-2.5">
// 				<div className="flex justify-around gap-5">
// 					<div className="h-8 w-full rounded-md bg-secondary-light"></div>
// 				</div>
// 			</div>
// 		</div>
// 	)
// }

export default SelectWithoutRegister
