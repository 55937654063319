import { AntdSelect, Input, SelectWithoutRegister, DateRangePicker, FeatureFlagWrapper } from "components"
import React, { useEffect, useMemo, useState } from "react"
import Button from "./Button"
import { useAppSelector } from "hooks"
import { selectBranchList } from "store/Branches/branch.slice"
import { selectEmployeeListByField, selectEmployeeListSorted } from "store/Employee/detail.slice"
import { selectPayGroupData } from "store/PayGroups/payGroup.slice"
import { selectLocationListByField } from "store/Location/locationDetails.slice"
import {
	Report as ReportInterface,
	Field,
	ENTITIES,
	reportsToMask,
	DATE_PICKER,
	ReportRadio,
} from "pages/reports/report-config"
import moment, { Moment } from "moment"
import { selectCustomerList } from "store/Customers/customers.slice"
import { selectSDSuppliersDropdownList } from "store/StaticData/SDSuppliers.slice"
import cx from "classnames"
import { AllowedTo, useAbac } from "react-abac"
import { IS_SUPER_ADMIN, Permissions } from "config/constants"
import { RangeValue } from "rc-picker/lib/interface"
import { DatePicker } from "antd"
import { FeatureFlagEnums } from "types/featureFlags.type"

interface Props {
	report: ReportInterface
	onSubmit: (name: string, data: any, additionalFields?: any, cb?: any) => void
	loading?: boolean
}
const defaultTypeOption = "pdf"

const defaultDependantOns = {
	[ENTITIES.BRANCH]: "",
	[ENTITIES.LOCATION]: "",
	[ENTITIES.EMPLOYEE]: "",
	[ENTITIES.PAY_GROUP]: "",
}
const Report: React.FC<Props> = ({ report, onSubmit, loading = false }) => {
	const { userHasPermissions } = useAbac()
	const [formData, setFormData] = useState<any>({ type: defaultTypeOption })
	const [selectedRadioOption, setSelectedRadioOption] = useState<number>(0)
	const [selectedFields, setSelectedFields] = useState<Field[]>()
	const [dependantOns, setDependantOns] = useState({ ...defaultDependantOns })
	const branches = useAppSelector(selectBranchList())
	const supplier = useAppSelector(selectSDSuppliersDropdownList)
	const employees =
		useAppSelector(selectEmployeeListByField(dependantOns.EMPLOYEE, formData?.[dependantOns.EMPLOYEE])) || []
	const employeesDropdown = useAppSelector(selectEmployeeListSorted()) || []
	const locations =
		useAppSelector(selectLocationListByField(dependantOns.LOCATION, formData?.[dependantOns.LOCATION])) || []
	const payGroupList = useAppSelector(selectPayGroupData())
	const customers = useAppSelector(selectCustomerList())

	const types = useMemo(() => {
		return userHasPermissions(Permissions.DOWNLOAD_REPORTS)
			? [
					{ title: "PDF", type: "pdf" },
					{ title: "Excel", type: "excel" },
			  ]
			: []
	}, [])

	const getOptions = (value: string) => {
		switch (value) {
			case ENTITIES.BRANCH:
				return branches?.map(({ name, _id }) => ({
					label: name,
					value: _id,
				}))
			case ENTITIES.SUPPLIER:
				return supplier
			case ENTITIES.EMPLOYEE:
				return employees?.map(({ _id, firstName, lastName }) => ({
					value: _id,
					label: `${firstName} ${lastName}`,
				}))
			case ENTITIES.LOCATION:
				return locations?.map(({ _id, name }) => ({
					value: _id,
					label: name,
				}))
			case ENTITIES.PAY_GROUP:
				return payGroupList?.map(({ name, _id }) => ({
					label: name,
					value: _id,
				}))
			case ENTITIES.CUSTOMER:
				return (
					customers?.map(({ name, _id }) => ({
						label: name,
						value: _id,
					})) || []
				)
			case ENTITIES.EMPLOYEES:
				return (
					[
						{ label: "Select Employee", value: "no-employee" },
						...employeesDropdown.map(employee => ({
							label: `${employee.firstName} ${employee.lastName}`,
							value: employee._id,
						})),
					] || []
				)
			default:
				return []
		}
	}

	const isDisabled = (value: string) => {
		switch (value) {
			case ENTITIES.BRANCH:
				return !branches.length
			case ENTITIES.EMPLOYEE:
				return !employees.length
			case ENTITIES.LOCATION:
				return !locations.length
			case ENTITIES.PAY_GROUP:
				return !payGroupList.length
			case ENTITIES.CUSTOMER:
				return !customers?.length
			default:
				return false
		}
	}

	const submitDisabled = useMemo(
		() => Object.keys(formData).filter(key => formData[key]).length !== (selectedFields?.length || 0) + 3,
		[formData],
	)

	useEffect(() => {
		setSelectedFields(report.radioButtons[0]?.fields)
		setSelectedRadioOption(0)
		const { startDate, endDate, type } = formData
		if (userHasPermissions(IS_SUPER_ADMIN)) {
			if (reportsToMask.includes(report.name)) setFormData((prev: any) => ({ ...prev, EMPLOYEES: "no-employee" }))
		} else setFormData({ type, startDate, endDate })
	}, [report])

	const handleInputChange = (radio: ReportRadio, index: number) => {
		const { startDate, endDate, type } = formData
		setFormData({ type, startDate, endDate })
		setSelectedRadioOption(index)
		setSelectedFields(radio.fields)
		if (!radio?.fields) setDependantOns({ ...defaultDependantOns })
		else
			radio?.fields.forEach(
				field => field?.dependsOn && setDependantOns({ ...dependantOns, [field.name]: field.dependsOn }),
			)
	}

	return (
		<div className="flex flex-col">
			<p className="text-lg font-bold">{report.title} </p>
			<p className="w-[60%]">{report.description}</p>
			{report.radioButtons.map((radio, index) => (
				<div key={`report_${index}`}>
					<Input
						className="w-10 cursor-pointer"
						type="radio"
						name="report"
						checked={index === selectedRadioOption}
						onChange={() => handleInputChange(radio, index)}
					/>
					<span className="cursor-pointer" onClick={() => handleInputChange(radio, index)}>
						{radio.title}
					</span>
				</div>
			))}
			<div className="flex flex-col gap-8 py-6">
				<div className="flex flex-wrap gap-8">
					{selectedFields?.map(({ multiple = false, ...field }) => {
						return (
							<React.Fragment key={field.name}>
								{field.name !== "LOCATION" &&
								field.name !== "EMPLOYEE" &&
								field.name !== "EMPLOYEES" ? (
									<SelectWithoutRegister
										disabled={isDisabled(field.name)}
										label={field.label}
										name={field.name}
										options={getOptions(field.name)}
										value={formData[field.name] ? formData[field.name] : ""}
										onChange={e => {
											setFormData({
												...formData,
												[field.name]: multiple
													? Array.from(e.target.selectedOptions, option => option.value)
													: e.target.value,
											})
										}}
										multiple={multiple}
									/>
								) : (
									<>
										{field.name === ENTITIES.EMPLOYEES ? (
											<FeatureFlagWrapper name={FeatureFlagEnums.SPECIAL_FEATURE}>
												<AllowedTo perform={IS_SUPER_ADMIN}>
													<AntdSelect
														isControlled
														onChange={e => {
															setFormData({
																...formData,
																[field.name]: e,
															})
														}}
														mode={multiple ? "multiple" : undefined}
														selected={formData[field.name] ?? []}
														label={field.label}
														name={field.name}
														containerClass="w-84 h-10! space-y-0"
														className={cx({
															" h-10! w-full ": true,
															"flex-nowrap overflow-x-clip": multiple,
														})}
														options={getOptions(field.name)}
														maxTagLength={28}
													/>
												</AllowedTo>
											</FeatureFlagWrapper>
										) : (
											<AntdSelect
												isControlled
												onChange={e => {
													setFormData({
														...formData,
														[field.name]: e,
													})
												}}
												mode={multiple ? "multiple" : undefined}
												selected={formData[field.name] ?? []}
												label={field.label}
												name={field.name}
												containerClass="w-84 h-10! space-y-0"
												className={cx({
													" h-10! w-full ": true,
													"flex-nowrap overflow-x-clip": multiple,
												})}
												options={getOptions(field.name)}
												maxTagLength={28}
											/>
										)}
									</>
								)}
							</React.Fragment>
						)
					})}
				</div>

				<div className="flex flex-wrap gap-8">
					{report.datePicker === DATE_PICKER.DEFAULT ? (
						<div className="flex flex-col">
							<label>Select Dates</label>
							<DateRangePicker
								format="DD/MM/YYYY"
								placeholder={["DD/MM/YYYY", "DD/MM/YYYY"]}
								allowClear={true}
								value={[
									formData?.startDate && moment(formData?.startDate),
									formData?.endDate && moment(formData?.endDate),
								]}
								onChange={(value: RangeValue<Moment>) => {
									value
										? setFormData({
												...formData,
												startDate: value[0]?.toISOString(),
												endDate: value[1]?.toISOString(),
										  })
										: setFormData({
												...formData,
												startDate: "",
												endDate: "",
										  })
								}}
							/>
						</div>
					) : (
						<div className="flex flex-col">
							<label>Select Month</label>
							<DatePicker
								style={{ border: "solid slate", height: "48px", minWidth: "330px" }}
								picker="month"
								size="large"
								placeholder="Select Month"
								format={"DD/MM/YYYY"}
								value={formData.startDate}
								onChange={value => {
									if (value)
										setFormData({
											...formData,
											startDate: moment(value).startOf("month"),
											endDate: moment(value).endOf("month"),
										})
								}}
							/>
						</div>
					)}
				</div>

				<div>
					{types.map((item, index) => (
						<React.Fragment key={index}>
							<Input
								type="radio"
								name="type"
								value={item.type}
								className="mt-10 w-10"
								defaultChecked={defaultTypeOption === item.type}
								onChange={({ target: { value } }) => {
									setFormData({ ...formData, type: value })
								}}
							/>
							<label>{item.title}</label>
						</React.Fragment>
					))}
				</div>
			</div>

			<div className="py-8">
				<Button
					disabled={submitDisabled || loading}
					loading={loading}
					onClick={() => {
						const additionalFields = report.radioButtons[selectedRadioOption]?.additionalFields
						onSubmit(report.name, formData, additionalFields)
					}}
				>
					Create Report
				</Button>
			</div>
		</div>
	)
}

export default Report
